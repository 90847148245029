<template>
      <div v-if="$auth.loggedIn">
          <div class="ml-5">Please go to <b-link to="/get-teaching-sessions" rel="noopener">Book Teaching Sessions</b-link> to book and get more sessions</div>
       </div>
       <b-overlay v-else :show="processing" class="container">
        <div class="mb-4">
           <div class="card mb-3">
               <div class="card-header">
             <h3>Purchase Teaching Sessions</h3>
           </div>
           <div class="card-body">
            <b-alert
                :show="!!error"
                fade
                variant="warning"
                dismissible
                @dismissed="error = null"
                >{{ error }}</b-alert
            >
           <irisNumberOfSessions :sessionPricePoints="sessionPricePoints" v-model="numberOfSessions" @totalPrice="price => totalPrice = price" @pricePerSession="price => costPerSession = price" class="nmSession"/>
           <b-row @click="numberOfSessions = sessionPricePoints[0].numberOfSessions" class="col-12 col-xl-6 p-0">
            <b-col cols="12" sm="4">{{ sessionPricePoints[0].numberOfSessions }}-{{ sessionPricePoints[1].numberOfSessions - 1 }} lessons:</b-col>
            <b-col cols="12" sm="8">{{ currenyformat(sessionPricePoints[0].cost / sessionPricePoints[0].numberOfSessions) }} per session</b-col>
           </b-row>
           <b-row @click="numberOfSessions = sessionPricePoints[1].numberOfSessions" class="col-12 col-xl-6 p-0 mb-2">
            <b-col cols="12" sm="4">{{ sessionPricePoints[1].numberOfSessions }} lessons:</b-col>
            <b-col cols="12" sm="8">{{ currenyformat(sessionPricePoints[1].cost / sessionPricePoints[1].numberOfSessions) }} per session</b-col>
           </b-row>
           <p>Billing Details:</p>
           <IrisFamilyInformation ref="familyInfo" :baseUrl="`${$REGISTRATION_URL}/iris/`" v-model="modelInfo.customerDetails" @valid="familyValid = $event" />
           <p>Card Details:</p>
           <IrisRecurly ref="irisRecurly" :tokenInfo="tokenInfo" @valid="recurlyValid = $event" class="IrisRecurlyStyle"/>
           </div>
           </div>
 
             <div @mouseover="familyInfo.$v.$touch()">
               <b-button variant="primary" :disabled="!valid" @click="process">Process Payment of {{ currenyformat(totalPrice) }}</b-button>
             </div>
             <div class="AskisMember">Are you an existing member? <b-link to="/members-area" rel="noopener">Sign-in here.</b-link></div> 
            </div>
       </b-overlay>
     
 </template>
 
 <script lang="ts">
 import { defineComponent } from "vue";
 import axios from 'axios';
 import type { NextSubidResponse } from '~~/iris/src/api';
 import type { CreateNonMemberAccountPost } from '~~/server/api/purchase/index.post';
 import type { AxiosError } from 'axios';
 import LoqateApi from '~~/iris/src/loqateApi';
 import { JwtTokenInterface } from '../login.vue';
 
 export default defineComponent({
 auth: false,
 middleware: ['auth'],
 head: {
   title: "EstiaLabs - Book Teaching Sessions"
 }
 });
 </script>
 
 
 <script setup lang="ts">
 
   /// <reference path="../../.nuxt/types/nitro-routes.d.ts" />
   /// <reference path="../../.nuxt/types/components.d.ts" />
   import { ref, reactive, computed } from 'vue'
   import { Address } from '@recurly/recurly-js';
   import type IrisRecurly from '../../components/iris/Recurly.vue'
   import type IrisFamilyInformation from '../../components/iris/FamilyInformation.vue'
   import jwtDecoder from "jwt-decode";
 
 import { InternalApi } from 'nitropack'
 
 const config = useRuntimeConfig()
 
 provide('$loqate', new LoqateApi({
   Key: config.public.loqateApiKey
 }))
 
 const currenyformat = (v: number) =>
  new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(
    v
  );

  const router = useRouter()


 const numberOfSessions = ref(1)
 const costPerSession = ref(0)
 const totalPrice = ref(0)
 const processing = ref(false)
 const error = ref<Error>()
 const result = ref<InternalApi["/api/purchase"]["post"]>()
 
 const irisRecurly = ref<InstanceType<typeof IrisRecurly> | null>()
   const familyInfo = ref<InstanceType<typeof IrisFamilyInformation> | null>()
 
   const recurlyValid = ref(false)
   const familyValid = ref(false)
   const subscriberId = ref<string>()
 
   const valid = computed(() => recurlyValid.value && familyValid.value)
 
   const instance = getCurrentInstance().proxy
 
 
   interface RecurlyTransactionError {
 /**
  * Object type
  */
 object: "transaction_error";
 /**
  * Transaction ID
  */
 transactionId: string;
 /**
  * Category
  */
 category: string;
 /**
  * Code
  */
 code: string;
 /**
  * Customer message
  */
 message: string;
 /**
  * Merchant message
  */
 merchantAdvice: string;
 /**
  * Returned when 3-D Secure authentication is required for a transaction. Pass this value to Recurly.js so it can continue the challenge flow.
  */
 threeDSecureActionTokenId: string;
 }
 
   /** axios response is a 3d secure request */
 const isThreeDSecureError = (
 e: any
 ): e is AxiosError<{ data: RecurlyTransactionError }> &
 Required<Pick<AxiosError<{ data: RecurlyTransactionError }>, "response">> => {
 return (
   axios.isAxiosError(e) &&
   e.response &&
   e.response.data &&
   e.response.data.data &&
   e.response.status === 422 &&
   e.response.data.data.code === "three_d_secure_action_required" &&
   e.response.data.data.threeDSecureActionTokenId
 );
 };
 
 // irisRecurly.value
 
   const modelInfo = reactive({
       customerDetails: {
           firstName: null,
           lastName: null,
           email: null,
           mobilePhone: null,
           emailConfirm: null,
           address1: null,
           city: null,
           postcode: null
       },
       /** subscriber id for creating */
       subscriberId: null
   })
 
   const sessionPricePoints: InternalApi['/api/subscription/:subscriberId']['get']['extraSessionCost'] = [{
       cost: 25,
       numberOfSessions: 1
   }, {
       cost: 200,
       numberOfSessions: 10
   }]
 
   const tokenInfo = computed<Address>(() => {
       return {
           first_name: modelInfo.customerDetails.firstName,
           last_name: modelInfo.customerDetails.lastName,
           phone: modelInfo.customerDetails.mobilePhone,
           address1: modelInfo.customerDetails.address1,
           city: modelInfo.customerDetails.city,
           country: 'GB',
           postal_code: modelInfo.customerDetails.postcode
       }
   })
 
   const process = async () => {
       error.value = null
       processing.value = true
       if (!valid.value) {
           return
       }
       if (!subscriberId.value) {
           subscriberId.value = await axios.post<NextSubidResponse>(`${instance.$REGISTRATION_URL}/api/iris/nextEstialabsSubscriberID`).then(v => v.data.signedNextSubscriberID)
       }
       const tokenId = await irisRecurly.value.token()
 
 
       const processPaymentWithOptionalToken = (
     threeDSecureActionResultTokenId?: string
   ) => {
     return instance.$axios
       .request<
         InternalApi["/api/purchase"]["post"]
       >({
         method: "POST",
         url: `/api/purchase`,
         data: {
           costPerSession: costPerSession.value,
           numberOfSessions: numberOfSessions.value,
           tokenId: tokenId.id,
           threeDSecureActionResultTokenId,
           firstName: modelInfo.customerDetails.firstName,
           lastName: modelInfo.customerDetails.lastName,
           mobilePhone: modelInfo.customerDetails.mobilePhone,
           address1: modelInfo.customerDetails.address1,
           address2: modelInfo.customerDetails.address1,
           city: modelInfo.customerDetails.city,
           postcode: modelInfo.customerDetails.postcode,
           email: modelInfo.customerDetails.email,
           subscriberIdToken: subscriberId.value,
         } as CreateNonMemberAccountPost,
       })
       .then(r => r.data);
   };
   try {
       const finalAjaxResult = await processPaymentWithOptionalToken().catch((error) => {
     if (isThreeDSecureError(error)) {
       // cardAuthActive.value = true;
       // const token = error.response.data.threeDSecureActionTokenId
       // debugger
       // data.error = error.response.data
       // 3d secure code
       return irisRecurly.value.cardAuth(error.response.data.data.threeDSecureActionTokenId)
         .then((result) => {
           // console.log(result)
           // cardAuthActive.value = false;
           return processPaymentWithOptionalToken(result.id);
         })
         .catch((e) => {
           // cardAuthActive.value = false;
           throw e;
         });
     } else {
       throw error;
     }
   });
   result.value = finalAjaxResult
   const resp = await instance.$auth.loginWith("refresh", {
         data: {
           username: result.value.username,
           password: result.value.password,
           institutes: ["ESTIALABS"],
         },
       })
       if (resp) {
           instance.$auth.setUser(jwtDecoder<JwtTokenInterface>(resp.data.token));
       }

       await router.push({
        name: 'get-teaching-sessions',
        params: {
          success: 'Payment confirmed!'
        }
       })
 
   } catch (e) {
    if (axios.isAxiosError(e)) {
        error.value = e.response.data.message;
    } else {
        error.value = e;
    }
   }
   processing.value = false

   }
 </script>
 
 <style scoped>
 .IrisRecurlyStyle {
   max-width: 520px;
 }
 .card-header {
 padding-bottom:unset;
 }
 .card-body {
     padding-top: 10px;
     padding-bottom: 10px;
     padding-left: 15px;
     padding-right: 10px;
 }
 .col-lg-12 {
   padding-left:unset;
   padding-right:unset;
 }
 .nmSession {
   margin-top:5px
 }
 .hero {
   margin-bottom: -20px;
 }
 
 .membersBtn {
   width: 14rem;
 }
 .hero {
   flex-direction: row;
 }
 .membersText {
     width: 80%;
 }
 .AskisMember {
 margin-bottom: 25px
 }
 
 @media screen and (min-width: 769px), print {
   .columns:not(.is-desktop) {
     display: block;
     margin-top: 3rem;
   }
 }
 
 @media only screen and (max-width: 512px) {
 .membersText {
     width: 100%;
   }
 }
 
 @media only screen and (max-width: 1023px) and (min-width: 513px) {
   .membersText {
     width: 439px;
   }
 }
 </style>
 